import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";

import { ImageWithDescription } from "../../utils/imageUtils";
import { getNextImage, getPrevImage } from "../../utils/lightBoxUtils";

import * as styles from "./LightBox.module.scss";

type LightBoxProps = {
  imgs: ImageWithDescription[];
  selectedImg: number;
  onClose: () => void;
};

const LightBox: React.FC<LightBoxProps> = ({ imgs, selectedImg, onClose }) => {
  const [currentImg, setCurrentImg] = useState(selectedImg);
  const size = imgs.length;

  const goToNext = () => {
    setCurrentImg((currentPos) => {
      return getNextImage(currentPos, size);
    });
  };

  const goToPrev = () => {
    setCurrentImg((currentPos) => {
      return getPrevImage(currentPos, size);
    });
  };

  const onKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case "ArrowLeft": {
        goToPrev();
        break;
      }
      case "ArrowRight": {
        goToNext();
        break;
      }
      case "Escape": {
        onClose();
        break;
      }

      default: {
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div aria-hidden="true" className={styles.lightBox}>
      <div className={styles.lightBox__background} onClick={onClose}></div>
      <FaTimes className={styles.lightBox__navClose} onClick={onClose} />
      <div className={styles.lightBox__content}>
        <GatsbyImage
          className={styles.lightBox__img}
          image={imgs[currentImg].image.gatsbyImageData}
          alt={imgs[currentImg].desc.title}
        />
        <div className={styles.lightBox__caption}>
          <div>
            <h2>{imgs[currentImg].desc.title}</h2>
            <p>{imgs[currentImg].desc.place}</p>
          </div>
          <div className={styles.lightBox__captionCounter}>
            {currentImg + 1} / {size}
          </div>
        </div>
        <FaAngleLeft className={styles.lightBox__navPrevious} onClick={goToPrev} />
        <FaAngleRight className={styles.lightBox__navNext} onClick={goToNext} />
      </div>
    </div>
  );
};

export default LightBox;
