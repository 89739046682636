import React from "react";

import socialLinks from "../../constants/social_links";

import * as styles from "./SocialLinks.module.scss";

const SocialLinks = () => {
  return (
    <ul className={styles.socialLinks}>
      {socialLinks.map((link) => {
        return (
          <li key={link.id} className={styles.socialLinks__link}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialLinks;
