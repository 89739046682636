import React, { useState } from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

import { FaBuilding } from "react-icons/fa";
import experiences from "../../constants/experience";
import * as styles from "./Experience.module.scss";

const sortedExperiences = experiences.sort((a, b) => b.order - a.order);

const Experience = () => {
  const [activeExp, setActiveExp] = useState(0);
  const { language } = useI18next();
  const { company, position, date, description, link } = sortedExperiences[activeExp];

  return (
    <section id="experience" className={styles.experience}>
      <h2 className="heading-2">
        <Trans>experience.name</Trans>
      </h2>
      <div className={styles.experience__center}>
        <div className={styles.card}>
          {/* btn container */}
          <div className={styles.btnContainer}>
            {sortedExperiences.map((item, index) => {
              const classes = [styles.jobBtn, index === activeExp ? styles.jobBtnActive : null];
              return (
                <button key={index} className={classes.join(" ")} onClick={() => setActiveExp(index)}>
                  {item.company}
                </button>
              );
            })}
          </div>
          {/* job info */}
          <article className={styles.jobInfo}>
            <h3>{position[language]}</h3>
            <div className={styles.jobInfo__company}>
              <a href={link} className={styles.jobInfo__companyLink} target="_blank" rel="noopener noreferrer">
                <FaBuilding /> &nbsp;
                {company}
              </a>
            </div>
            <p className={styles.jobInfo__date}>{date[language]}</p>
            <div>{description[language]}</div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Experience;
