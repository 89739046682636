import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { FaTimes } from "react-icons/fa";

import createLocalizedPath from "../utils/pathUtils";
import LanguageSelector from "./ui/LanguageSelector";
import pageLinks from "../constants/links";

import * as styles from "./Sidebar.module.scss";

type SidebarProps = {
  onClose: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { language, originalPath, defaultLanguage } = useI18next();

  return (
    <aside className={styles.sidebar}>
      <button className={styles.sidebar__btnClose} type="button" onClick={onClose}>
        <FaTimes />
      </button>
      <div>
        <ul className={styles.sidebar__links}>
          {pageLinks.map((pageLink) => (
            <li key={pageLink.id} className={styles.sidebar__linksLink}>
              <AnchorLink
                to={createLocalizedPath(originalPath, pageLink.url, language, defaultLanguage)}
                title={t(`section.${pageLink.key}`)}
                onAnchorLinkClick={onClose}
              />
            </li>
          ))}
        </ul>
        <LanguageSelector onClose={onClose} />
      </div>
    </aside>
  );
};

export default Sidebar;
