import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";

import zipImageWithDesc, { ImageQueryResp, ImageWithDescription } from "../../utils/imageUtils";
import Gallery from "../ui/Gallery";
import photoDescriptions from "../../constants/photos";

import * as styles from "./Hobbies.module.scss";

const query = graphql`
  {
    gallery: allFile(filter: { relativeDirectory: { eq: "photos" } }, sort: { fields: base, order: DESC }) {
      nodes {
        id
        base
        thumb: childImageSharp {
          gatsbyImageData(placeholder: BLURRED, webpOptions: { quality: 50 }, breakpoints: [576, 768, 992, 1200, 1480])
        }
        full: childImageSharp {
          gatsbyImageData(placeholder: BLURRED, webpOptions: { quality: 90 })
        }
      }
    }
  }
`;

const Hobbies = () => {
  const { gallery } = useStaticQuery(query);

  const photos: ImageQueryResp[] = gallery.nodes;
  const photosWithDescriptions: ImageWithDescription[] = zipImageWithDesc(photos, photoDescriptions);

  return (
    <section id="hobbies" className={styles.hobbies}>
      <h2 className="heading-2">
        <Trans>hobbies.name</Trans>
      </h2>
      <div className={styles.hobbies__content}>
        <p className={styles.hobbies__intro}>
          <Trans>hobbies.text</Trans>
        </p>
        <Gallery gallery={photosWithDescriptions} />
        <a href="https://www.eyeem.com/u/vpphotos" className="btn">
          <Trans>hobbies.photos</Trans> &rarr;
        </a>
      </div>
    </section>
  );
};

export default Hobbies;
