import React from "react";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";

import createLocalizedPath from "../../utils/pathUtils";

import * as styles from "./Logo.module.scss";

const Logo = () => {
  const { language, originalPath, defaultLanguage } = useI18next();

  return (
    <AnchorLink to={createLocalizedPath(originalPath, "#hero", language, defaultLanguage)}>
      <StaticImage src="../../assets/images/logo.png" alt="VP logo" placeholder="tracedSVG" className={styles.logo} />
    </AnchorLink>
  );
};

export default Logo;
