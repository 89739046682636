import React, { Fragment, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { ImageWithDescription } from "../../utils/imageUtils";

import * as styles from "./Gallery.module.scss";
import LightBox from "./LightBox";

type PhotoWithDescription = {
  gallery: ImageWithDescription[];
};

type ModalState = {
  isOpen: boolean;
  selectedImg: number;
};

const initModalState: ModalState = { isOpen: false, selectedImg: 0 };

const Gallery: React.FC<PhotoWithDescription> = ({ gallery }) => {
  const [modal, setModal] = useState(initModalState);

  const openModal = (id: number) => {
    setModal({
      isOpen: true,
      selectedImg: id,
    });
  };

  const closeModal = () => {
    setModal((state) => {
      return { isOpen: false, selectedImg: state.selectedImg };
    });
  };

  return (
    <Fragment>
      <div className={styles.gallery}>
        {gallery.map((item, index) => {
          return (
            <div className={styles.gallery__photo} key={item.id} onClick={() => openModal(index)} aria-hidden="true">
              <GatsbyImage
                image={item.thumb.gatsbyImageData}
                imgClassName={styles.gallery__photoImg}
                alt={item.desc.title}
              />
              <div className={styles.gallery__photoTitle}>{item.desc.title}</div>
            </div>
          );
        })}
      </div>
      {modal.isOpen && <LightBox imgs={gallery} selectedImg={modal.selectedImg} onClose={closeModal} />}
    </Fragment>
  );
};

export default Gallery;
