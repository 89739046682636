import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

import * as styles from "./Contact.module.scss";

const Contact = () => {
  return (
    <section id="contact" className={styles.contact}>
      <h2 className="heading-2">
        <Trans>contact.name</Trans>
      </h2>
      <div className={styles.contact__content}>
        <div className={styles.contact__contentText}>
          <Trans>contact.text</Trans>
        </div>
        <a href="mailto:vladimir@piven.eu" className={`btn ${styles.contact__contentBtn}`}>
          <Trans>contact.hello</Trans> &rarr;
        </a>
      </div>
    </section>
  );
};

export default Contact;
