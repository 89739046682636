import React, { PropsWithChildren, useState } from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";
import SEO from "./Seo";
import Sidebar from "./Sidebar";

import "../assets/scss/main.scss";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <SEO />
      <div id="root">
        <Navbar toggleSidebar={toggleSidebar} />
        {isSidebarOpen && <Sidebar onClose={toggleSidebar} />}
        <main>{children}</main>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
