import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans } from "gatsby-plugin-react-i18next";

import { FiChevronsRight } from "react-icons/fi";

import * as styles from "./About.module.scss";

const skills = [
  "Java",
  "Spring & Spring Boot",
  "React & Gatsby",
  "Node.js",
  "Python",
  "Java-/Type-Script",
  "HTML",
  "CSS",
];

const About = () => {
  return (
    <section id="about" className={styles.about}>
      <h2 className="heading-2">
        <Trans i18nKey="about.name">Wer bin ich</Trans>
      </h2>
      <div className={styles.about__center}>
        <div>
          <div className={styles.about__intro}>
            <Trans i18nKey="about.intro">
              Hallo! Mein Name ist <strong>Vladimir Piven</strong> und ich komme ursprünglich aus der Ukraine, lebe aber
              schon seit 18 Jahren in Deutschland. Nachdem ich 2013 mein Bioinformatik-Studium an der Universität
              Tübingen erfolgreich abgeschlossen habe, begann ich als <strong>Java-Software-Entwickler</strong> zu
              arbeiten. In den letzten 8 Jahren habe ich viel Erfahrung sowohl in der <strong>Backend-</strong> als auch
              in der <strong>Frontend-Entwicklung</strong> gesammelt. Ich versuche immer, meine Fähigkeiten zu
              verbessern und neue Technologien zu lernen.
              <br /> Hier sind einige der Technologien, mit denen ich mich auskenne:
            </Trans>
          </div>
          <ul className={styles.about__skills}>
            {skills.map((skill, index) => {
              return (
                <li key={index} className={styles.about__skill}>
                  <span className={styles.about__skillIcon}>
                    <FiChevronsRight />
                  </span>{" "}
                  {skill}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.about__picture}>
          <div className={styles.about__pictureWrapper}>
            <StaticImage
              src="../../assets/images/hero.jpg"
              alt="Vladimir Piven"
              className={styles.about__pictureImg}
              placeholder="tracedSVG"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
