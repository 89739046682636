import React, { useEffect, useState } from "react";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { FaAlignRight } from "react-icons/fa";

import createLocalizedPath from "../utils/pathUtils";
import LanguageSelector from "./ui/LanguageSelector";
import Logo from "./ui/Logo";
import pageLinks from "../constants/links";

import * as styles from "./Navbar.module.scss";

type NavbarProps = {
  toggleSidebar: () => void;
};

const Navbar: React.FC<NavbarProps> = ({ toggleSidebar }) => {
  const { t } = useTranslation();
  const { language, originalPath, defaultLanguage } = useI18next();
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      setShowShadow(window.pageYOffset !== 0);
    };

    document.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => {
      document.removeEventListener("scroll", updatePosition);
    };
  }, []);

  const classes = [showShadow ? styles.navbar__shadow : null, styles.navbar];

  return (
    <header className={styles.header}>
      <nav className={classes.join(" ")}>
        <div className={styles.navbar__center}>
          <div className={styles.navbar__header}>
            <Logo />
            <button type="button" className={styles.navbar__btnToggle} onClick={toggleSidebar}>
              <FaAlignRight />
            </button>
          </div>
          <div className={styles.navbar__links}>
            {pageLinks.map((pageLink) => (
              <AnchorLink
                key={pageLink.id}
                to={createLocalizedPath(originalPath, pageLink.url, language, defaultLanguage)}
                title={t(`section.${pageLink.key}`)}
                className={styles.navbar__linksLink}
              />
            ))}
          </div>
          <div className={styles.navbar__lang}>
            <LanguageSelector />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
