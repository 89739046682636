import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import SocialLinks from "./ui/SocialLinks";

import * as styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <SocialLinks />
      <div className={styles.footer__hint}>
        <span className={styles.footer__hintText}>scroll</span>
      </div>
      <div className={styles.footer__copyright}>
        &copy; <span>Vladimir Piven</span> {new Date().getFullYear()}, <Trans>footer.copyright</Trans>
      </div>
    </footer>
  );
};

export default Footer;
