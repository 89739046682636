// extracted by mini-css-extract-plugin
export var lightBox = "LightBox-module--light-box--+IdHW";
export var lightBox__background = "LightBox-module--light-box__background--Z+0fi";
export var lightBox__caption = "LightBox-module--light-box__caption--CASzB";
export var lightBox__captionCounter = "LightBox-module--light-box__caption-counter--N7-xw";
export var lightBox__content = "LightBox-module--light-box__content--L-TZZ";
export var lightBox__img = "LightBox-module--light-box__img--yNnzK";
export var lightBox__navClose = "LightBox-module--light-box__nav-close--eb5Wg";
export var lightBox__navNext = "LightBox-module--light-box__nav-next--zEho5";
export var lightBox__navPrevious = "LightBox-module--light-box__nav-previous--cTo-s";
export var photoMoveIn = "LightBox-module--photoMoveIn--2E97E";