import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { FaFileDownload } from "react-icons/fa";

import createLocalizedPath from "../../utils/pathUtils";

import * as styles from "./Hero.module.scss";

const Hero = () => {
  const { t } = useTranslation();
  const { language, originalPath, defaultLanguage } = useI18next();

  return (
    <section id="hero" className={styles.hero}>
      <article>
        <div className={styles.hero__underline}></div>
        <h1 className={`heading-1 ${styles.hero__infoHeader}`}>{t("hero.welcome")}</h1>
        <p className={styles.hero__infoText}>{t("hero.intro")}</p>
        <div className={styles.hero__infoLinks}>
          <a href="/CV_Piven.pdf" download className="btn">
            <Trans>hero.downloadCV</Trans>
            &nbsp;
            <FaFileDownload />
          </a>
          <AnchorLink
            to={createLocalizedPath(originalPath, "#contact", language, defaultLanguage)}
            title={t("section.contact")}
            className="btn"
          />
        </div>
      </article>
    </section>
  );
};

export default Hero;
