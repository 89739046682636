export const getNextImage = (index: number, maxSize: number) => {
  if (index < 0) {
    return 0;
  }

  const next = index + 1;
  return next < maxSize ? next : 0;
};

export const getPrevImage = (index: number, maxSize: number) => {
  if (index >= maxSize) {
    return maxSize - 1;
  }

  const prev = index - 1;
  return prev >= 0 ? prev : maxSize - 1;
};
