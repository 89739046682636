import React from "react";
import { graphql } from "gatsby";

import About from "../components/section/About";
import Contact from "../components/section/Contact";
import Experience from "../components/section/Experience";
import Hero from "../components/section/Hero";
import Hobbies from "../components/section/Hobbies";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Experience />
      <Hobbies />
      <Contact />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
