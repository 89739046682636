import { IGatsbyImageData } from "gatsby-plugin-image";

export type ImageQueryResp = {
  id: string;
  base: string;
  thumb: any;
  full: any;
};

export type ImageDescription = {
  title: string;
  place: string;
};

export type ImageDescriptionMap = {
  [id: string]: {
    title: string;
    place: string;
  };
};

type GatsbyImageWrapper = {
  gatsbyImageData: IGatsbyImageData;
};

export type ImageWithDescription = {
  id: string;
  image: GatsbyImageWrapper;
  thumb: GatsbyImageWrapper;
  desc: ImageDescription;
};

const zip = (imgs: ImageQueryResp[], descriptions: ImageDescriptionMap): ImageWithDescription[] => {
  const res: ImageWithDescription[] = [];
  imgs.map((img) => {
    const id = img.base.split(".")[0];
    let desc: ImageDescription = descriptions[id];
    if (!desc) {
      desc = {
        title: id,
        place: "",
      };
    }
    res.push({
      id: img.id,
      image: img.full,
      thumb: img.thumb,
      desc,
    });
  });
  return res;
};

export default zip;
