// extracted by mini-css-extract-plugin
export var btnContainer = "Experience-module--btn-container--ZXAZB";
export var card = "Experience-module--card--yf0lG";
export var experience = "Experience-module--experience--KVV1L";
export var experience__center = "Experience-module--experience__center--QB-ut";
export var jobBtn = "Experience-module--job-btn--JYE8B";
export var jobBtnActive = "Experience-module--job-btn-active--qcIPF";
export var jobInfo = "Experience-module--job-info--fQDfJ";
export var jobInfo__company = "Experience-module--job-info__company--FPv7c";
export var jobInfo__companyLink = "Experience-module--job-info__company-link--G2EXL";
export var jobInfo__date = "Experience-module--job-info__date---liV3";