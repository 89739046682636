import React from "react";

import { FaFacebookF, FaXing, FaLinkedinIn } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImInstagram } from "react-icons/im";

const data = [
  {
    id: 1,
    icon: <FaFacebookF />,
    url: "https://www.facebook.com/vladimir.piven",
  },
  {
    id: 2,
    icon: <FaXing />,
    url: "https://www.xing.com/profile/Vladimir_Piven2/cv",
  },
  {
    id: 3,
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/in/vladimir-piven/",
  },
  {
    id: 4,
    icon: <ImInstagram />,
    url: "https://www.instagram.com/piven.vladimir/",
  },
  {
    id: 5,
    icon: <GrMail />,
    url: "mailto:vladimir@piven.eu",
  },
];

export default data;
