type Links = {
  id: number;
  key: string;
  url: string;
};

const links: Links[] = [
  {
    id: 1,
    key: "about",
    url: "#about",
  },
  {
    id: 2,
    key: "experience",
    url: "#experience",
  },
  {
    id: 3,
    key: "hobbies",
    url: "#hobbies",
  },
  {
    id: 4,
    key: "contact",
    url: "#contact",
  },
];

export default links;
