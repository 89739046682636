import { ImageDescriptionMap } from "../utils/imageUtils";

const photos: ImageDescriptionMap = {
  "photo-01": { title: "Flowers in the park", place: "Mdina, Malta, September 2019" },
  "photo-02": { title: "Nature vs. Metal", place: "Malta, September 2019" },
  "photo-03": { title: "Tug Boat Rozi", place: "Malta, September 2019" },
  "photo-04": { title: "Corals", place: "Malta, September 2019" },
  "photo-05": { title: "The Past", place: "Malta, September 2019" },
  "photo-06": { title: "Sunset", place: "Azure Window, Gozo, Malta, September 2019" },
  "photo-07": { title: "Cheese and wine", place: "Cittadella Victoria, Gozo, Malta, September 2019" },
  "photo-08": { title: "Hibiscus rosa-sinensis", place: "Saint Julian, Malta, September 2019" },
  "photo-09": { title: "Twilight", place: "Buchau (camping island), Germany, July 2020" },
  "photo-10": { title: "Pause on the shore", place: "Buchau (camping island), Germany, July 2020" },
  "photo-11": { title: "The cones", place: "Bad Wildbad, Germany, September 2020" },
  "photo-12": { title: "Lonely lotus", place: "Bebenhausen Abbey, Germany, September 2020" },
  "photo-13": { title: "Evening", place: "Esslingen am Neckar, Germany, December 2020" },
  "photo-14": { title: "Festive Owl", place: "Bad Mergentheim, Germany, December 2020" },
  "photo-15": { title: "White wolves", place: "Cleebronn, Germany, January 2022" },
  "photo-16": { title: "Deserted house", place: "Schwarzwald, Germany, May 2022" },
};

export default photos;
