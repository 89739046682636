import React, { FocusEventHandler, useState } from "react";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import { MdLanguage } from "react-icons/md";

import * as styles from "./LanguageSelector.module.scss";

type LanguageSelectorProps = {
  onClose?: () => void;
};

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleOnBlur: FocusEventHandler = (event) => {
    if (event.relatedTarget?.tagName !== "A" && isOpen) {
      toggleDropdown();
    }
  };

  const { language, languages, originalPath } = useI18next();
  return (
    <div id="lang" className={styles.lang} tabIndex={0} onClick={toggleDropdown} onBlur={handleOnBlur}>
      <div className={styles.lang__selected}>
        <MdLanguage className={styles.lang__selectedIcon} />
        {language}
      </div>
      {isOpen && (
        <ul className={styles.lang__dropdown}>
          {languages.map((lng) => (
            <li key={lng} className={styles.lang__item}>
              <Link to={originalPath} language={lng} onClick={onClose}>
                <Trans ns="langs">{lng}</Trans>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
