type LangMap = {
  [lang: string]: string;
};

type Experience = {
  order: number;
  date: LangMap;
  position: LangMap;
  company: string;
  link: string;
  description: LangMap;
};

const experiences: Experience[] = [
  {
    order: 1,
    date: {
      de: "Januar 2014 - November 2018",
      en: "January 2014 - November 2018",
      ua: "Січень 2014 - Листопад 2018",
      ru: "Январь 2014 - Ноябрь 2018",
    },
    position: { de: "Software-Entwickler", en: "Software developer", ua: "Розробник ПЗ", ru: "Разработчик ПО" },
    company: "ISTEC GmbH",
    link: "http://istec.de/",
    description: {
      de: "Beratung von Unternehmen (z.B. Bosch, BMW, Daimler) in der Qualitätsicherung für Software, Erstellung von Richtlinien zur Testautomatisierung und Entwicklung von automatisierten Software-Tests.",
      en: "Consulting of companies (e.g. Bosch, BMW, Daimler) in quality assurance for software, creation of test automation guidelines and development of automated software tests.",
      ua: "Консультування компаній (наприклад, Bosch, BMW, Daimler) щодо забезпечення якості програмного забезпечення, створення інструкцій для автоматизації тестування та розробки автоматизованих тестів програмного забезпечення.",
      ru: "Консультирование компаний (например: Bosch, BMW, Daimler) по вопросам обеспечения качества программного обеспечения, создания руководства по автоматизации тестирования и разработки автоматизированных тестов программного обеспечения.",
    },
  },
  {
    order: 2,
    date: {
      de: "Dezember 2018 - Juli 2021",
      en: "December 2018 - July 2021",
      ua: "Грудень 2018 - Липень 2021",
      ru: "Декабрь 2018 - Июль 2021",
    },
    position: {
      de: "Software-web-developer",
      en: "Software web developer",
      ua: "Веб-розробник ПЗ",
      ru: "Веб-разработчик ПО",
    },
    company: "Kittelberger media solutions GmbH",
    link: "https://www.kittelberger.de/",
    description: {
      de: "Erstellung und Pflege von individuellen Webseiten für Unternehmen (z.B. Bosch, Theben, Dremel).",
      en: "Creation and maintenance of individual websites for companies (e.g. Bosch, Theben, Dremel).",
      ua: "Створення та підтримка індивідуальних веб-сайтів для компаній (наприклад, Bosch, Theben, Dremel).",
      ru: "Создание и поддержка индивидуальных веб-сайтов для компаний (например, Bosch, Theben, Dremel).",
    },
  },
  {
    order: 3,
    date: {
      de: "August 2021 - Heute",
      en: "August 2021 - Today",
      ua: "Серпень 2021 - Сьогодні",
      ru: "Август 2021 - Сегодня",
    },
    position: {
      de: "Senior software engineer",
      en: "Senior software engineer",
      ua: "Старший інженер-програміст",
      ru: "Старший инженер-программист",
    },
    company: "adesso SE",
    link: "https://www.adesso.de",
    description: {
      de: "Umsetzung neuer Anforderungen in bestehende Anwendungen sowie Bug-Analyse dieser Anwendungen.",
      en: "Implementation of new requirements in existing applications as well as bug analysis of these applications.",
      ua: "Впровадження нових вимог у існуючі додатки та аналіз помилок у цих додатках.",
      ru: "Внедрение новых требований в существующие приложения и анализ ошибок в этих приложениях.",
    },
  },
];

export default experiences;
